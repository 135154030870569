exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-work-focus-jsx": () => import("./../../../src/pages/work/focus.jsx" /* webpackChunkName: "component---src-pages-work-focus-jsx" */),
  "component---src-pages-work-maketimeflow-jsx": () => import("./../../../src/pages/work/maketimeflow.jsx" /* webpackChunkName: "component---src-pages-work-maketimeflow-jsx" */),
  "component---src-pages-work-ourbalance-jsx": () => import("./../../../src/pages/work/ourbalance.jsx" /* webpackChunkName: "component---src-pages-work-ourbalance-jsx" */),
  "component---src-pages-work-sparkle-jsx": () => import("./../../../src/pages/work/sparkle.jsx" /* webpackChunkName: "component---src-pages-work-sparkle-jsx" */)
}

